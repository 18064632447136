import "core-js/modules/es.array.filter.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tableList from "./table.vue"; //表格

export default {
  components: {
    tableList: tableList
  },
  data: function data() {
    return {
      form: {
        prjId: "",
        modelStatus: "",
        fileName: "",
        buId: "",
        functionId: "",
        functionName: ""
      },
      statusList: [],
      projectlist: [],
      dicList: {}
    };
  },
  watch: {
    'form.functionId': function formFunctionId(val) {
      if (val) {
        this.form.functionId = val;
        this.form.functionName = this.dicList.functionDicList.filter(function (item) {
          return item.dicId === val;
        })[0].dicName || '';
      } else {
        this.form.functionId = '';
        this.form.functionName = '';
      }

      this.$refs.tables.queryEvent();
    }
  },
  computed: {
    recordId: function recordId() {
      return this.$route.query.recordId;
    },
    releaseTime: function releaseTime() {
      return this.$route.query.releaseTime;
    }
  },
  methods: {
    returnPage: function returnPage() {
      this.$router.go(-1);
    },
    //获取字典列表
    getDicList: function getDicList(val) {
      this.dicList = val;
    }
  }
};