var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "maintain" }, [
    _c(
      "div",
      { staticClass: "maintain-header" },
      [
        _c(
          "el-button",
          {
            attrs: { plain: "", size: "small" },
            on: { click: _vm.returnPage }
          },
          [_vm._v("返回上一层")]
        ),
        _c(
          "el-breadcrumb",
          { staticClass: "bread", attrs: { separator: "/" } },
          [
            _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
              _vm._v("必审清单")
            ]),
            _c("el-breadcrumb-item", [
              _c("a", { attrs: { href: "/" } }, [_vm._v("发布记录")])
            ])
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "header" },
      [
        _c(
          "el-form",
          { ref: "form", attrs: { model: _vm.form, inline: "" } },
          [
            _c(
              "el-form-item",
              { attrs: { label: "所属角色" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { size: "small", clearable: "", filterable: "" },
                    model: {
                      value: _vm.form.functionId,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "functionId", $$v)
                      },
                      expression: "form.functionId"
                    }
                  },
                  _vm._l(_vm.dicList.functionDicList, function(item) {
                    return _c("el-option", {
                      key: item.dicId,
                      attrs: { label: item.dicName, value: item.dicId }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c("el-form-item", { staticClass: "releaseTime" }, [
              _c("div", [_vm._v("发布时间:" + _vm._s(_vm.releaseTime))])
            ])
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "table" },
      [
        _c("table-list", {
          ref: "tables",
          attrs: { type: 2, form: _vm.form, recordId: _vm.recordId },
          on: { getDicList: _vm.getDicList }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }